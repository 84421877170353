/* themes.css (veya mevcut CSS dosyanız) */

/* ===========================
   TEMA DEĞİŞKENLERİ (Aynen koru)
   =========================== */
   .theme-red {
    --background-color: #fff5f5;
    --text-color: #b71c1c;
    --card-background: #ffcdd2;
    --button-background: #f44336;
    --button-hover: #d32f2f;
    --title-color: #ff6600;
  }
  
  .theme-purple {
    --background-color: #f3e5f5;
    --text-color: #4a148c;
    --card-background: #ce93d8;
    --button-background: #9c27b0;
    --button-hover: #7b1fa2;
    --title-color: #4a148c;
  }
  
  .theme-orange {
    --background-color: #fff3e0;
    --text-color: #e65100;
    --card-background: #ffe0b2;
    --button-background: #fb8c00;
    --button-hover: #ef6c00;
    --title-color: #ff6600;
  }
  
  .theme-pink {
    --background-color: #fce4ec;
    --text-color: #880e4f;
    --card-background: #f8bbd0;
    --button-background: #ec407a;
    --button-hover: #d81b60;
    --title-color: #880e4f;
  }
  
  .theme-dark {
    --background-color: #1a1a1a;
    --text-color: #ffffff;
    --card-background: #2c2c2c;
    --button-background: #ff6600;
    --button-hover: #e65c00;
    --title-color: #ffcc00;
  }
  
  .theme-blue {
    --background-color: #e3f2fd;
    --text-color: #0d47a1;
    --card-background: #bbdefb;
    --button-background: #1e88e5;
    --button-hover: #1565c0;
    --title-color: #0d47a1;
  }
  
  .theme-green {
    --background-color: #e8f5e9;
    --text-color: #1b5e20;
    --card-background: #c8e6c9;
    --button-background: #43a047;
    --button-hover: #2e7d32;
    --title-color: #1b5e20;
  }
  
  /* Default tema değişkenleri (harf-izgarasi-container.theme-default) */
  .harf-izgarasi-container.theme-default {
    --background-color: transparent;
    --text-color: #333;
    --card-background: #f2f2f2;
    --title-color: #ff6600;
  }
  
  /* ===============================
     ÖZEL ANİMASYONLAR (Aynen koru)
     =============================== */
  /* Doğru/yanlış animasyonları */
  @keyframes correctAnimation {
    0%   { transform: scale(1); }
    50%  { transform: scale(1.2); }
    100% { transform: scale(1); }
  }
  
  @keyframes incorrectAnimation {
    0%   { transform: scale(1); }
    50%  { transform: scale(0.8); }
    100% { transform: scale(1); }
  }
  
  @keyframes fadeInOut {
    0%   { opacity: 0; }
    10%  { opacity: 1; }
    90%  { opacity: 1; }
    100% { opacity: 0; }
  }
  
  .harf-izgarasi-letter.correct { 
    animation: correctAnimation 0.5s forwards;
  }
  
  .harf-izgarasi-letter.incorrect {
    animation: incorrectAnimation 0.5s forwards;
  }
  
  .harf-izgarasi-message { 
    animation: fadeInOut 1s ease-in-out;
  }
  